import React, { useState, useEffect } from 'react';
import TeamTable from './TeamTable';
import PlayerTable from './PlayerTable';
import StyleMatchup from './StyleMatchup';
import './AirYardsDashboard.css';

interface Team {
  name: string;
  airYardsPerGame: number;
  yacAllowedPerGame: number;
  airYacAllowedPerGame: number;
  opponent: string;
  gamesPlayed: number;
}

interface Player {
  name: string;
  team: string;
  gamesPlayed: number;
  airYards: number;
  airYardsPerGame: number;
  aDot: number;
  yac: number;
  yacPerGame: number;
  airYacPerGame: number;  // Add this line
  opponent: string;
}

const teamNameToAbbreviation: { [key: string]: string } = {
  "Atlanta Falcons": "ATL",
  "Buffalo Bills": "BUF",
  "Carolina Panthers": "CAR",
  "Chicago Bears": "CHI",
  "Cincinnati Bengals": "CIN",
  "Cleveland Browns": "CLE",
  "Indianapolis Colts": "IND",
  "Arizona Cardinals": "ARI",
  "Dallas Cowboys": "DAL",
  "Denver Broncos": "DEN",
  "Detroit Lions": "DET",
  "Green Bay Packers": "GNB",
  "Houston Texans": "HOU",
  "Jacksonville Jaguars": "JAX",
  "Kansas City Chiefs": "KAN",
  "Miami Dolphins": "MIA",
  "Minnesota Vikings": "MIN",
  "New Orleans Saints": "NOR",
  "New England Patriots": "NWE",
  "New York Giants": "NYG",
  "New York Jets": "NYJ",
  "Tennessee Titans": "TEN",
  "Philadelphia Eagles": "PHI",
  "Pittsburgh Steelers": "PIT",
  "Las Vegas Raiders": "LVR",
  "Los Angeles Rams": "LAR",
  "Baltimore Ravens": "BAL",
  "Los Angeles Chargers": "LAC",
  "Seattle Seahawks": "SEA",
  "San Francisco 49ers": "SFO",
  "Tampa Bay Buccaneers": "TAM",
  "Washington Commanders": "WAS"
};

const AirYardsDashboard: React.FC = () => {
  const [teams, setTeams] = useState<Team[]>([]);
  const [players, setPlayers] = useState<Player[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log('Fetching data...');
        const sheetId = '1XHH4PcvF9P9XWQOeY78wGAePn3_vLYC7ITRwdHwBXTg';
        const apiKey = 'AIzaSyAxiabivfLLyjvVnF1JnGHi64UHW1h1g6Y';

        // Fetch team data
        const teamSheetName = 'TeamStats';
        const teamUrl = `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values/${teamSheetName}?key=${apiKey}`;
        const teamResponse = await fetch(teamUrl);
        const teamData = await teamResponse.json();

        // Fetch opponent data
        const opponentSheetName = 'Opponents';
        const opponentUrl = `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values/${opponentSheetName}?key=${apiKey}`;
        const opponentResponse = await fetch(opponentUrl);
        const opponentData = await opponentResponse.json();

        console.log('Opponent data:', opponentData.values);

        // Process opponent data
        const opponentMap = new Map<string, string>();
        opponentData.values.slice(1).forEach((row: string[]) => {
          opponentMap.set(row[0], row[1]);
          opponentMap.set(row[1], row[0]); // Add reverse mapping
        });

        console.log('Opponent map:', Array.from(opponentMap.entries()));

        // After fetching team data
        console.log('Raw team data:', teamData.values);

        // Update processed team data with opponent information and per-game calculations
        const processedTeamData: Team[] = teamData.values.slice(1).map((row: string[]) => {
          const teamName = row[0];
          const teamAbbreviation = teamNameToAbbreviation[teamName];
          const opponent = opponentMap.get(teamAbbreviation);
          const gamesPlayed = parseInt(row[1], 10);
          return {
            name: teamName,
            airYardsPerGame: parseInt(row[7], 10) / gamesPlayed,
            yacAllowedPerGame: parseInt(row[8], 10) / gamesPlayed,
            airYacAllowedPerGame: (parseInt(row[7], 10) + parseInt(row[8], 10)) / gamesPlayed,
            opponent: opponent ? teamNameToAbbreviation[opponent] || opponent : 'Unknown',
            gamesPlayed: gamesPlayed
          };
        });

        // Create a mapping of team abbreviations to their opponents
        const teamToOpponent = new Map<string, string>();
        processedTeamData.forEach(team => {
          const abbr = teamNameToAbbreviation[team.name];
          if (abbr) {
            teamToOpponent.set(abbr, team.opponent);
          }
        });

        // Fetch player data
        const playerSheetName = 'PlayerStats';
        const playerUrl = `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values/${playerSheetName}?key=${apiKey}`;
        const playerResponse = await fetch(playerUrl);
        const playerData = await playerResponse.json();

        // Process player data with per-game calculations
        const processedPlayerData: Player[] = playerData.values.slice(1)
          .filter((row: string[]) => 
            row[4] === 'WR' && 
            row[1] !== 'Player' && 
            parseFloat(row[16]) * parseInt(row[7], 10) > 100
          )
          .map((row: string[]) => {
            const gamesPlayed = parseInt(row[5], 10);
            const targets = parseInt(row[7], 10);
            const aDot = parseFloat(row[16]);
            const airYards = aDot * targets;
            const yac = parseInt(row[14], 10);
            const airYardsPerGame = airYards / gamesPlayed;
            const yacPerGame = yac / gamesPlayed;
            return {
              name: row[1].replace(/[*+]/g, ''),
              team: row[23],
              gamesPlayed: gamesPlayed,
              airYards: airYards,
              airYardsPerGame: airYardsPerGame,
              aDot: aDot,
              yac: yac,
              yacPerGame: yacPerGame,
              airYacPerGame: airYardsPerGame + yacPerGame,
              opponent: teamToOpponent.get(row[2]) || 'Unknown'
            };
          });

        setTeams(processedTeamData);
        setPlayers(processedPlayerData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (teams.length === 0) {
    return <div>No team data available. Please check the console for errors.</div>;
  }

  return (
    <div className="air-yards-dashboard">
      <h1>AirYAC Dashboard</h1>

      <section className="style-matchup">
        <h2>Best Matchups</h2>
        <StyleMatchup players={players} teams={teams} />
      </section>
      
      <section className="team-section">
        <h2>Team Statistics</h2>
        <TeamTable teams={teams} />
      </section>

      <section className="player-section">
        <h2>Player Statistics (WRs)</h2>
        <PlayerTable players={players} />
      </section>
    </div>
  );
};

export default AirYardsDashboard;

export { teamNameToAbbreviation };